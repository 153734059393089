import React, { FC } from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import { minWidth } from '../../../common/styles/helpers';

export const HowItWorksBanner: FC = () => {
  return (
    <MythBustersWrapper>
      <Typography variant="h2" fontWeight={600} marginBottom={3}>
        Získej jako bonus certifikát o absolvování kurzu!
      </Typography>

      <Wrapper>
        <Box>
          <Typography fontWeight={600} marginBottom={2}>
            Jak to vlastně funguje?
          </Typography>

          <Typography marginBottom={2}>
            Každá kapitola začíná a končí stejným kvízem. Ověříš si tak, jak ti
            naši podnikatelé a odborníci pomohli s orientací v tématu.
          </Typography>

          <Typography marginBottom={1}>
            1. Otevři si kapitolu a vyplň vstupní kvíz
          </Typography>
          <Typography marginBottom={1}>
            2. Dokoukej celé vzdělávací video
          </Typography>
          <Typography marginBottom={1}>3. Vyplň výstupní kvíz </Typography>
          <Typography marginBottom={2}>
            <b>
              4. Když to zvládneš u všech kapitol do 30. 8. otevře se ti finální
              dotazník a získáš certifikát o absolvování kurzu.
            </b>
          </Typography>

          <Typography>
            Výsledky kvízů jsou především zpětnou vazbou pro tebe. Nemají vliv
            na dokončení kapitoly, kurzu ani na získání certifikátu.
          </Typography>
        </Box>

        <img src="/people.jpg" alt="lidé" />
      </Wrapper>
    </MythBustersWrapper>
  );
};

const MythBustersWrapper = styled.div`
  padding: 8px;
  position: relative;
  z-index: 2;
`;

const Wrapper = styled.div`
  img {
    display: none;
  }

  ${minWidth.mobile} {
    display: flex;

    img {
      flex-shrink: 0;
      display: block;
      width: 100%;
      max-width: 290px;
      height: max-content;
    }
  }
`;
