import React, { FC } from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { colors } from '../../../common/styles/colors';
import CheckIcon from '@mui/icons-material/Check';
type Props = {
  isCompleted: boolean;
  src: string;
};

export const ImageThumbnailWithCompleted: FC<Props> = ({
  src,
  isCompleted,
}) => {
  return (
    <Box position="relative" borderRadius={1} overflow="hidden" height="100%">
      <ImageThumbnail src={src} />
      {isCompleted && (
        <CompletedOverlay>
          <CheckIcon color="inherit" fontSize="large" />
        </CompletedOverlay>
      )}
    </Box>
  );
};

const ImageThumbnail = styled.img`
  width: 100%;
  height: 100%;
`;

const CompletedOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: ${colors.brand};
  color: white;
  opacity: 0.8;
  display: grid;
  place-items: center;
`;
