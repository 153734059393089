import { gql } from '@apollo/client';

export const GET_CHAPTERS = gql`
  query getChapters {
    getChapters {
      id
      isEntryQuizDone
      isExitQuizDone

      slug
      title
      duration
      thumbnail {
        id
        url
      }
    }
  }
`;
