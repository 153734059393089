import React, { FC } from 'react';
import styled from 'styled-components';
import { Card } from '@mui/material';
import { inspect } from 'util';
import { colors } from '../styles/colors';

export const BeigeCard: FC = ({ children }) => {
  return <StyledCard elevation={0}>{children}</StyledCard>;
};

const StyledCard = styled(Card)`
  background-color: ${colors.lightBeige2};
  box-shadow: none;
  border: none;
`;

export const LightBeigeCard = styled(BeigeCard)`
  background-color: ${colors.lightBeige};
`;
