import { default as React, useMemo } from 'react';
import { NextPage } from 'next';
import { MainLayout } from '../common/containers/MainLayout/MainLayout';
import { useQuery } from '@apollo/client';

import Typography from '@mui/material/Typography';

import { ChaptersList } from './dashboard/components/ChaptersList';
import { LoadingScreen } from '../common/components/LoadingScreen';

import { Box, Button, Grid, Stack } from '@mui/material';

import { BeigeCard, LightBeigeCard } from '../common/components/BeigeCard';
import { GET_CHAPTERS } from './dashboard/gql/getChapters.query';
import { getChapters } from './dashboard/gql/__generated__/getChapters';
import { CompletedCounter } from './dashboard/components/CompletedCounter';
import Link from 'next/link';
import { Routes } from '../common/enums/routes';
import styled from 'styled-components';
import { minWidth } from '../common/styles/helpers';
import { HowItWorksBanner } from './dashboard/components/HowItWorksBanner';

const COMMON_MAX_COLUMN_WIDTH = 675;

const ProjectDetailPage: NextPage = () => {
  const { data, loading } = useQuery<getChapters>(GET_CHAPTERS, {});

  const completedCount = useMemo(
    () => data?.getChapters.filter((chapter) => chapter.isExitQuizDone).length,
    [data?.getChapters]
  );

  const totalCount = useMemo(
    () => data?.getChapters.length,
    [data?.getChapters]
  );

  const courseCompleted = useMemo(
    () => completedCount === totalCount,
    [completedCount, totalCount]
  );
  console.log('process.env ==============', process.env.NEXT_PUBLIC_VERCEL_ENV);

  return (
    <MainLayout protectedRoute title={`StartupBox`} maxWidth={1010}>
      {loading && <LoadingScreen logo={false} />}

      {data && (
        <Stack
          spacing={4}
          alignItems="center"
          display="flex"
          justifyContent="center"
        >
          {completedCount > 1 && (
            <Box width="100%">
              <BeigeCard>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography fontWeight={500} fontSize={24}>
                    Vítej zpět
                  </Typography>

                  <CompletedCounter
                    completedCount={completedCount}
                    totalCount={totalCount}
                  />
                </Grid>
              </BeigeCard>
            </Box>
          )}

          <BeigeCard>
            <HowItWorksBanner />
          </BeigeCard>

          <Stack spacing={4} maxWidth={COMMON_MAX_COLUMN_WIDTH} width="100%">
            <LightBeigeCard>
              <ChaptersList contentPages={data.getChapters} />

              <Stack marginTop={6} alignItems="center">
                <Box width={220}>
                  <Link href={Routes.Final}>
                    <Button
                      variant="contained"
                      disabled={!courseCompleted}
                      fullWidth
                    >
                      Získat certifikát
                    </Button>
                  </Link>
                </Box>

                {!courseCompleted && (
                  <Typography maxWidth={422} align="center" marginTop={2}>
                    Certifikát si budeš moci stáhnout až po dokončení všech
                    vzdělávacích kapitol :) je za odměnu.
                  </Typography>
                )}
              </Stack>
            </LightBeigeCard>

            <BeigeCard>
              <PoweredByWrapper>
                <Typography>
                  Powered by <b>Civitta</b> & <b>StartupBox</b>
                </Typography>

                <img src="/eu.png" alt="eu flag" />
              </PoweredByWrapper>
            </BeigeCard>
          </Stack>
        </Stack>
      )}
    </MainLayout>
  );
};

export default ProjectDetailPage;

const PoweredByWrapper = styled.div`
  width: 100%;
  max-width: ${COMMON_MAX_COLUMN_WIDTH};
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 80%;
  }

  ${minWidth.mobile} {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    img {
      width: 220px;
    }
  }
`;
