import { default as React, FC } from 'react';
import { Box, Card, Grid, Hidden } from '@mui/material';
import Link from 'next/link';
import styled, { css } from 'styled-components';
import Button from '@mui/material/Button';
import { colors } from '../../../common/styles/colors';
import Typography from '@mui/material/Typography';

import { Routes } from '../../../common/enums/routes';

import { minWidth } from '../../../common/styles/helpers';

import { getChapters_getChapters } from '../gql/__generated__/getChapters';
import { ImageThumbnailWithCompleted } from './ImageThumbnailWithCompleted';

type Props = {
  contentPages: getChapters_getChapters[];
};

export const ChaptersList: FC<Props> = ({ contentPages }) => {
  return (
    <>
      {contentPages.map(
        ({ id, slug, thumbnail, isExitQuizDone, title, duration }, index) => (
          <React.Fragment key={id}>
            <Link
              href={{
                pathname: Routes.Detail,
                query: {
                  pageId: slug,
                },
              }}
            >
              <Box>
                <TaskLinkButton
                  color="primary"
                  // onClick={() => handleClick(task.howToPage)}
                >
                  <Box
                    height="48px"
                    display="grid"
                    gridTemplateColumns={'80px auto'}
                    columnGap={2}
                    alignItems="center"
                    overflow="hidden"
                  >
                    <ImageThumbnailWithCompleted
                      isCompleted={isExitQuizDone}
                      src={thumbnail.url}
                    />

                    <Typography variant="body1" fontWeight={600} align="left">
                      {title}
                    </Typography>
                  </Box>

                  <Hidden smDown>
                    <Typography
                      variant="body2"
                      color={'#AAAAAA'}
                      className="test"
                    >
                      {duration} minut
                    </Typography>
                  </Hidden>
                </TaskLinkButton>
              </Box>
            </Link>

            {index + 1 !== contentPages.length && <Divider />}
          </React.Fragment>
        )
      )}
    </>
  );
};

const TaskLinkButton = styled(Button)`
  width: 100%;
  height: auto;

  display: flex;
  justify-content: space-between;
  overflow: hidden;

  font-size: 14px;

  ${minWidth.mobile} {
    padding: 0.5rem 2rem;
    font-size: 16px;
  }
`;

const Divider = styled.div`
  height: 1px;
  background-color: #cccccc;
  margin: 0 1rem;

  ${minWidth.mobile} {
    margin: 0.5rem 2rem;
  }
`;
