import React, { FC } from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';
import { minWidth } from '../../../common/styles/helpers';

type Props = {
  totalCount: number;
  completedCount: number;
};

export const CompletedCounter: FC<Props> = ({ totalCount, completedCount }) => {
  return (
    <Wrapper>
      <Typography>
        <Trans>Témata, která už máš v malíčku</Trans>
      </Typography>

      <Box
        marginLeft={2}
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box zIndex={2}>
          <CircularProgress
            size={58}
            variant="determinate"
            value={(completedCount / totalCount) * 100}
            thickness={7}
          />
        </Box>

        <Box position="absolute" top={0} zIndex={1}>
          <CircularProgress
            size={58}
            variant="determinate"
            color="secondary"
            value={100}
            thickness={7}
          />
        </Box>
        <Box position="absolute">
          <Typography fontWeight={600} fontSize={14}>
            {completedCount}/{totalCount}
          </Typography>
        </Box>
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;

  ${minWidth.mobile} {
    margin-top: 0;
  }
`;
